
.p-speeddial-button.p-button.p-button-icon-only {
  width: 2rem;
  height: 2rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1rem;
}

//.speeddial-linear-demo .p-speeddial-direction-right {
//  //top: calc(50%);
//  //left: calc(50% - 2rem);
//}

.p-speeddial {
 position: relative;
  /* display: flex; */
   //z-index: 1;
}


.p-speeddial-action {
  width: 1.5rem;
  height: 1.5rem;
  background: #ff4081;
  color: #fff;
  position: absolute;
  z-index: 2;
}

.p-rowgroup-header-name {
  width: 100%;
}

.p-rowgroup-header{
  td {
    padding: 0.5px !important;
  }
}


.rowGroupHeaderSpan {
  float: right;
}
/* todo: @Frank bitte an der richtigen Stelle einfügen */
#root .project-card-img{
  background-color: white;
}