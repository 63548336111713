@import '../../public/assets/sass/custom_colors';
@import '_variables.scss';

html {
  overflow: hidden;
}

.dev {
  pointer-events: none;
  position: fixed;
  top: 10px;
  left: -30px;
  z-index: 10000;
  height: 100px;
  text-align: center;
}

.bandarole {
  margin: auto;
  display: inline-block;
  font-size: 12px;
  width: 100px;
  text-align: center;
  color: black;
  transform: rotate(-45deg);
  border: 2px solid #fff;
  background: yellow;
}

.bug-report {
  position: absolute;
  bottom: 0;
  left: -80px;
  z-index: 10000;
  //width: 30px;
  height: 30px;
  background-color: grey;
  border-top-right-radius: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    left: 0;
  }

  svg {
    margin: auto;
    color: white;
    fill: white;
    padding-top: 7px;
  }
}

//
//* {
//  font-family: "Font Awesome 5", serif;
//}

#root {
  .layout-breadcrumb-container {
    //margin-bottom: 65px;
    position: fixed;
    z-index: 90;

    .layout-breadcrumb-left-items {
      //position: fixed;
      top: 0;
      //margin: 1rem 2rem;
      padding: unset;
      margin: 0 1rem 0 1rem;
      z-index: 99;
    }

    .layout-breadcrumb-right-items {
      height: 80px;
      position: fixed;
      top: 0;
      right: 0;
      margin: 1rem;
      padding: 0;
      z-index: 99;

      .card {
        margin: 0;
      }
    }
  }

  .layout-topbar {
    //background-color: $DarkBlue;
    //pointer-events: none;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100px;
    display: inherit;
    z-index: 90;
  }

  .layout-main-content {
    position: relative;
    margin-top: 100px;
    overflow: auto;

    &.geomap {
      margin-top: 0;
      padding: 0;

      .ol-zoom {
        position: absolute;
        top: 105px;
        left: 14px;
      }

      .ctrl-map {
        position: absolute;
        top: 190px;
        left: 14px;
      }

      .toolkit-ctrl-map {
        position: absolute;
        overflow: hidden;
        top: 230px;
        left: 14px;
        z-index: 100;
        display: flex;
        max-width: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        transition: all 0.3s ease-in-out;

        &.open {
          max-width: 500px;
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
      }
    }
  }

  .p-dataview.p-component.p-dataview-list {
    overflow: auto;
    position: absolute;
    top: 10px;
    right: 16px;
    left: 16px;
    bottom: 100px;
  }

  .p-dataview.p-component.p-dataview-grid {
    overflow: auto;
    position: absolute;
    top: 10px;
    right: 16px;
    left: 16px;
    bottom: 60px;
  }

  .project-overview {
    overflow: hidden;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
  }

  .p-link {
    border: none;
  }

  .layout-inline-menu-action {
    padding-left: 0;
  }

  .user-icon {
    //padding-left: 1.5rem;
    margin-left: 0.7rem;
    font-size: 1.5rem;
  }

  .menu-logo {
    position: absolute;
    left: 0;
    padding-left: 10px;
    padding-bottom: 40px !important;
    transition: left 0.2s ease-in-out;
    width: 100%;
  }

  .menu-pin {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .logo {
    border-radius: 0;
    position: absolute;
    top: 15px;
    left: 10px;
    transition: left 0.2s ease-in-out;


    &.logo-app {
      top: 30px;
      left: 3px;

      img {
        width: 200px;
      }
    }

    &.hide {
      top: 65px;
      left: -250px;
      transition: all 0.2s ease-in-out;

      img {
        width: 200px;
      }
    }

    img {
      width: 100px;
      height: auto !important;
    }
  }


  .layout-menu {
    padding: 0;
    width: 100%;
    margin: auto;
  }

  .layout-menu-container {
    margin-top: 105px;
  }

  .layout-menu-wrapper .layout-menu-container .layout-menu > li:first-child {
    margin-top: 0;
  }

  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a > .p-badge {
    position: absolute;
    left: 30px;
    visibility: visible;
  }

  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li > a > .p-badge {
    position: inherit;
    left: inherit;
    top: inherit;
  }

  .layout-inline-menu .layout-inline-menu-action-panel {
    padding: 0;
  }

  .project-card {
    //max-height: 250px;
    //min-height: 250px;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;

    .product-grid-item-content {
      overflow: hidden;
    }

    .project-card-img {
      height: 60px !important;
      border-radius: 10px;
      overflow: hidden;
      margin: auto;
      padding: 5px;

      img {
        max-height: 45px !important;
        object-fit: contain;
        height: 100%;
      }
    }

    .project-number {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      padding-left: 10px;
      padding-bottom: 3px;
    }
  }

  .no-field-margin {
    .field {
      margin: 0;
    }
  }

  .iframe-wrapper {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
  }

  .project-edit-form.project-group-overview .project-group-list {
    max-height: 190px;
    overflow: auto;
  }

  .p-inputnumber-input {
    width: 100%;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.input-label {
  margin-bottom: 0 !important;
  //font-size: 1.2rem;
}

.custom-inputfield {
  margin-top: 0 !important;
}

.custom-input {
  .field {
    margin: 0;
  }
}

.p-datatable-scrollable .p-datatable-tbody > tr > td {
  display: block;
}

.expander-column {
  //display: inline !important;
  flex: none !important;
  width: 3em;
}

.selection-column {
  max-width: 3em;
}

.cell-function {
  &:hover {
    color: darken(white, 30%);
  }
}

.wait-animation {
  position: absolute;
  top: 500px;
  left: 500px;
  z-index: 9;
  width: 200px;
  height: 200px;

  .w-all {
    width: 28px;
    height: 28px;
    padding: 0;
    float: left;
    background-color: rgb(250, 250, 250);
    -moz-border-radius: 30px;
    -webkit-border-bottom: 30px;
    -khtml-border-bottom-: 30px;
    border-radius: 30px;
    border: 1px solid rgba(250, 250, 250, 0.5);
  }

  .w-top {
    margin: 0 70px;
  }

  .w-top-left {
    margin: -5px 40px 10px 15px;
  }

  .w-top-right {
    margin: -5px 15px 10px 40px;
  }

  .w-left {
    margin: 10px 55px 10px 0;
  }

  .w-right {
    margin: 10px 0 10px 55px;
  }

  .w-bottom-left {
    margin: 5px 40px -10px 20px;
  }

  .w-bottom-right {
    margin: 5px 20px -10px 30px;
  }

  .w-bottom {
    margin: -00px 70px 0px 70px;
  }
}

.layout-main-content-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.scroll-card-main-content {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  margin: 0 !important;
  position: absolute;
  overflow: auto;
}

.wizard-scroll-card-content {
  overflow: auto;
  position: absolute;
  top: 160px;
  left: 24px;
  right: 24px;
  bottom: 150px;
  margin: 0 !important;
}

.wizard-button-card {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 50px;
}

.hide-x {
  .p-dialog-header-icon.p-dialog-header-close.p-link {
    display: none;
  }
}

.grafana-buttons {
  position: absolute;

  /*! CSS Used from: https://grafana.dmt.de/public/build/grafana.light.1bdf8d32cc2516e2475b.css */
  svg:not(:root) {
    overflow: hidden;
  }

  button {
    color: inherit;
    font: inherit;
    margin: 0;
    box-shadow: none !important;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
    cursor: pointer;
  }

  *, :after, :before {
    box-sizing: inherit;
  }

  button {
    touch-action: manipulation;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button {
    border-radius: 0;
    line-height: inherit;
    margin: 0;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar:hover {
    height: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar-track-piece {
    background-color: #0000;
  }

  /*! CSS Used from: Embedded */
  .css-wf08df-Icon {
    display: inline-block;
    line-height: 0;
  }

  .css-eyx4do {
    vertical-align: middle;
    display: inline-block;
    fill: currentcolor;
  }

  .p-button {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 32px;
    padding: 0px 8px;
    line-height: 30px;
    font-weight: 500;
    border: 1px solid rgba(36, 41, 46, 0.12);
    white-space: nowrap;
    transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(36, 41, 46, 0.75);
    background-color: rgb(255, 255, 255);
  }

  .p-button:focus {
    outline: transparent dotted 2px;
    outline-offset: 2px;
    box-shadow: rgb(244, 245, 245) 0px 0px 0px 2px, rgb(56, 113, 220) 0px 0px 0px 4px;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 0.2s;
    transition-property: outline, outline-offset, box-shadow;
    z-index: 1;
  }

  .p-button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  .p-button:hover {
    box-shadow: rgba(24, 26, 27, 0.2) 0px 1px 2px;
  }

  .p-button:disabled {
    cursor: not-allowed;
    opacity: 0.38;
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:disabled:hover {
    color: rgba(36, 41, 46, 0.5);
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:hover {
    color: rgb(36, 41, 46);
    background: rgb(244, 245, 245);
  }

  .css-1kl6l1j > button:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  /*! CSS Used from: Embedded */
  button {
    letter-spacing: 0.01071em;
  }

  button:focus {
    outline: none;
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.57143;
  }

  button {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
}

.wiki-buttons {
  position: absolute;
  top: 72px;

  /*! CSS Used from: https://grafana.dmt.de/public/build/grafana.light.1bdf8d32cc2516e2475b.css */
  svg:not(:root) {
    overflow: hidden;
  }

  button {
    color: inherit;
    font: inherit;
    margin: 0;
    box-shadow: none !important;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
    cursor: pointer;
  }

  *, :after, :before {
    box-sizing: inherit;
  }

  button {
    touch-action: manipulation;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button {
    border-radius: 0;
    line-height: inherit;
    margin: 0;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar:hover {
    height: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar-track-piece {
    background-color: #0000;
  }

  /*! CSS Used from: Embedded */
  .css-wf08df-Icon {
    display: inline-block;
    line-height: 0;
  }

  .css-eyx4do {
    vertical-align: middle;
    display: inline-block;
    fill: currentcolor;
  }

  .p-button {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 32px;
    padding: 0px 8px;
    line-height: 30px;
    font-weight: 500;
    border: 1px solid rgba(36, 41, 46, 0.12);
    white-space: nowrap;
    transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(36, 41, 46, 0.75);
    background-color: rgb(255, 255, 255);
  }

  .p-button:focus {
    outline: transparent dotted 2px;
    outline-offset: 2px;
    box-shadow: rgb(244, 245, 245) 0px 0px 0px 2px, rgb(56, 113, 220) 0px 0px 0px 4px;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 0.2s;
    transition-property: outline, outline-offset, box-shadow;
    z-index: 1;
  }

  .p-button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  .p-button:hover {
    box-shadow: rgba(24, 26, 27, 0.2) 0px 1px 2px;
  }

  .p-button:disabled {
    cursor: not-allowed;
    opacity: 0.38;
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:disabled:hover {
    color: rgba(36, 41, 46, 0.5);
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:hover {
    color: rgb(36, 41, 46);
    background: rgb(244, 245, 245);
  }

  .css-1kl6l1j > button:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  /*! CSS Used from: Embedded */
  button {
    letter-spacing: 0.01071em;
  }

  button:focus {
    outline: none;
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.57143;
  }

  button {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
}

.alarm_alarm, .alarm_ok, .alarm_warning, .alarm_timeout {
  background-color: red;
  display: block;
  width: auto;
  height: auto;
  padding: 10px;
  color: #2E323F;
}

.alarm_alarm {
  background-color: #FC6161;
  color: #FFFFFF;
}

.alarm_ok {
  background-color: #8CF000;
}

.alarm_warning, .alarm_timeout {
  background-color: #EEE500;
  color: #2E323F;
}

@keyframes p-progress-spinner-color {
  0% {
    stroke: #001ed2;
  }
  100% {
    stroke: #00003c;
  }
}

#id_d {
  animation: loading-bar-morph 1.5s linear .0s infinite;
  transform-origin: center
}

#id_m {
  animation: loading-bar-morph 1.5s linear .2s infinite;
  transform-origin: center
}

#id_t {
  animation: loading-bar-morph 1.5s linear .4s infinite;
  transform-origin: center;
}

@keyframes loading-bar-morph {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

.p-tabview-nav-next {
  margin-left: 20px;
}


// ---> Pulsierendes Upload Icon

.layout-sidebar-active {
  .upload-status {
    ul {
      width: 100% !important;
    }
  }
}

.upload-status {

  position: fixed;
  width: 100%;
  //width: 100%;
  bottom: 0;
  left: -500px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  //background: none;

  &.begin {
    ul {
      width: 100% !important;
    }
  }

  ul {
    width: auto;
  }

  &.begin {
    transform: scale(1.5);
    max-width: 400px;
    bottom: 50%;
    left: -500 !important;
    transition: all 0.5s ease-in-out;
    padding: 15px;
  }

  &.started {
    left: 0;
  }

  i {
    animation-name: opacity;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
  }

  transition: all 0.5s ease-in-out;

  @keyframes opacity {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
}

// <---

.temp-data {
  .p-column-header-content {
    .p-checkbox {
      // Header Checkbox der CustomDataTable für "Alles auswählen" ausblenden
      display: none;
    }
  }
}

.p-progressbar-determinate .p-progressbar-value-animate {
  transition: width 0.01s ease-in-out;
}

//#personDataTable, #parcelDataTable {
//  .p-datatable-row-expansion {
//    max-height: 400px;
//
//    > td {
//      overflow: auto;
//    }
//  }
//}

.top-button {
  display: none;
}

//.p-column-filter-buttonbar {
//  padding: 0 !important;
//}

.edit.add-button {
  .p-tabview-nav-container {
    margin-left: 40px;
    padding-top: 10px;
  }

  .p-tabview-panels {
    padding-top: 10px;
  }
}


.card {
  .status-card {
    position: absolute;
    top: -13px;
    left: -16px;
  }
}

.edit-dialog {
  width: 93%;
  max-width: inherit;
  height: 100vh;
}

.map-panel {
  position: relative;

  &:hover .pointer-events-none .map-info-active {
    display: inline-block;
  }

  .map-info-active {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2000;
    background-color: white;
    border-bottom-left-radius: 10px;
    padding: 0.5rem;
  }
}

.p-accordion-header-text {
  width: 100%;
}

.accordion-addition {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.protocoll-table {
  border-collapse: collapse;

  & td, & th {
    padding: 10px;
    border: 1px solid grey;
  }
}

.p-datatable-flex-scrollable .p-datatable-wrapper {
  display: inline-block;
}

@media (max-width: $tabletBreakpoint) {
  #root {

    .top-button {
      display: inherit;
      position: absolute;
      bottom: 5px;
      left: 5px;
    }

    .layout-topbar {
      pointer-events: none;
      height: 60px;
    }

    .layout-topbar-left {
      pointer-events: all;
    }

    //.layout-menu-container {
    //  margin-top: 50px;
    //}

    .layout-main-content {
      margin-top: 0;
      margin-left: 35px;

      &:has(canvas) {
        margin-left: 0px;
      }
    }

    .layout-breadcrumb-container {
      .layout-breadcrumb-right-items {
        height: 30px;
        right: 20px;
        top: -15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        max-width: 100px;

        .card {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          padding: 2px;
        }
      }
    }

    //.logo {
    //  border-radius: 0;
    //  position: absolute;
    //  top: 10px;
    //  left: 4px;
    //  transition: left 0.2s ease-in-out;
    //
    //
    //  &.logo-app {
    //    top: 30px;
    //
    //    img {
    //      width: 110px;
    //    }
    //  }
    //
    //  &.hide {
    //    top: 65px;
    //    left: -150px;
    //    transition: all 0.2s ease-in-out;
    //
    //    img {
    //      width: 100px;
    //    }
    //  }
    //
    //  img {
    //    width: 110px;
    //    height: auto !important;
    //  }
    //}

    .layout-main-content-header {
      position: relative;
    }

    .p-dataview.p-component.p-dataview-list {
      top: 10px;
      right: 16px;
      left: 16px;
      bottom: 135px;
    }

    .p-dataview.p-component.p-dataview-grid {
      top: 10px;
      right: 16px;
      left: 16px;
      bottom: 110px;
    }

    .p-datatable {
      max-width: 500px !important;
      margin: auto !important;
    }
  }

  .scroll-card-main-content {
    position: static !important;
  }

  .utilisation-input {
    max-width: 100px;
  }
}

.error .p-radiobutton-box {
  border-color: red;
}

//.accordion-header-1 .p-accordion-header-link {
//  background-color: #ffe699 !important;
//}
//
//.accordion-header-2 .p-accordion-header-link {
//  background-color: #d9e1f2 !important;
//}
//
//.accordion-header-3 .p-accordion-header-link {
//  background-color: #e2efda !important;
//}
//
//.accordion-header-4 .p-accordion-header-link {
//  background-color: #f8cbad !important;
//}

.color-picker-protocoll {
  border: 1px solid grey;
  padding: 0.2rem;
  border-radius: 7px;
}

.circle-picker {
  span:first-child div span div {
    border: 1px solid grey;
  }
}

.p-column-filter-constraints {
  display: flex;

  .p-column-filter-constraint {
    width: 100%;
  }
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  border-bottom: inherit;
}

.last-import-container {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 30px;
  padding-right: 15px;
  font-size: 1.25rem;
  z-index: 100;

  .p-card-content {
    padding-top: 0.05rem;
    padding-bottom: 0.05rem;
  }

  &+.spinner {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;

    .p-progress-spinner {
      margin: auto;
    }
  }
}

.last-import-accordion {
  table, th, td {
    border: 1px solid grey;
    border-collapse: collapse;
    padding: 0.5rem;
  }
}

@media (max-width: $phoneBreakpoint) {
  #root {
    .top-button {
      display: inherit;
      position: absolute;
      top: 14px;
      bottom: inherit;
      left: 40px;
    }

    .layout-topbar {
      pointer-events: none;
    }

    .layout-topbar-left {
      pointer-events: all;
    }

    .layout-main-content {
      margin-top: 60px;
      margin-left: 0px;

      &:has(canvas) {
        margin-left: 0px;
      }
    }

    .layout-breadcrumb-container {
      .layout-breadcrumb-right-items {
        height: 30px;
        right: 15px;
        top: -15px;

        .card {
          padding: 2px;
        }
      }
    }

    .layout-main-content-header {
      position: relative;
    }

    .p-dataview.p-component.p-dataview-list {
      top: 10px;
      right: 16px;
      left: 16px;
      bottom: 135px;
    }

    .p-dataview.p-component.p-dataview-grid {
      top: 10px;
      right: 16px;
      left: 16px;
      bottom: 160px;
    }
  }
}
