.fm {
  .fm-body {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 220px;
    overflow-y: auto;
  }

  .fm-content {
    border: none;
    position: relative;
    word-break: break-all;
    width: 150px;
    height: 115px;
    cursor: pointer;

    &.selected {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);

      & .fm-delete-button {
        display: inline-block;
      }
    }

    .fm-filename {
      //font-size: 1vw;
    }
  }

  .fm-image img {
    -o-object-fit: contain;
    object-fit: contain;
    height: 40px;
  }

  .fm-delete-button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  .fm-button-controls {
    border-top: 1px solid rgba(0, 0, 0, 0.38);
    border-right: 1px solid rgba(0, 0, 0, 0.38);
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
